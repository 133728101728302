import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Col, Row } from "antd"

import { Color, Path } from "../config"

const StyledMenuRow = styled(Row)`
  height: 40px;
  background-color: ${Color.WHITE};
  color: ${Color.BLACK};
`

const StyledMenuCol = styled(Col)`
  text-align: center;
`

const StyledLink = styled(Link)`
  color: ${Color.BLACK};
  font-weight: bold;
  :hover {
    text-decoration: line-through;
    color: ${Color.BLACK};
  }
`

const StyledA = styled.a`
  color: ${Color.BLACK};
  font-weight: bold;
  :hover {
    text-decoration: line-through;
    color: ${Color.BLACK};
  }
`

const MenuBrowser = () => {
  return (
    <StyledMenuRow align="middle">
      <StyledMenuCol span={6}>
        <StyledLink to={Path.PROJECTS} activeClassName="menu-active">
          PROJECTS
        </StyledLink>
      </StyledMenuCol>
      <StyledMenuCol span={6}>
        <StyledLink to={Path.SERVICES} activeClassName="menu-active">
          SERVICES
        </StyledLink>
      </StyledMenuCol>
      <StyledMenuCol span={6}>
        <StyledLink to={Path.ABOUTUS} activeClassName="menu-active">
          ABOUT US
        </StyledLink>
      </StyledMenuCol>
      <StyledMenuCol span={6}>
        <StyledA target="_blank" href={Path.SHOP}>
          SHOP
        </StyledA>
      </StyledMenuCol>
    </StyledMenuRow>
  )
}

export default MenuBrowser
