import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import styled from "styled-components"
import { Path } from "../config"

const StyledLogo = styled.img`
  width: 120px;
  z-index: 999999;
  margin-left: 25px;
  @media (min-width: 769px) {
    width: 200px;
  }
`
const StyledLink = styled(Link)`
  @media (max-width: 769px) {
    position: fixed;
    z-index: 9;
  }
`
const DefaultLogo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.gif" }) {
        publicURL
      }
    }
  `)

  return (
    <StyledLink to={Path.HOME}>
      <StyledLogo src={data.logo.publicURL} />
    </StyledLink>
  )
}

export default DefaultLogo
