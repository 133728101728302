import React from "react"
import styled from "styled-components"

import { GlobalStyles } from "./global-styles"
import News from "./news"
import Menu from "./menu"
import SEO from "./seo"
import { Color } from "../config"
import Logo from "./logo"

import "../styles/styles.css"

interface LayoutProps {
  children?: React.ReactNode
  backgroundColor?: string
  title: string
  description: string
}

const Layout = ({
  children,
  backgroundColor = Color.BLACK,
  title,
  description,
}: LayoutProps) => {
  return (
    <LayoutContainer>
      <SEO title={title} description={description} />
      <GlobalStyles bgColor={backgroundColor} />
      <Main>
        <Logo />
        {children}
      </Main>
      <StyledFooter>
        <Menu />
        <News />
      </StyledFooter>
    </LayoutContainer>
  )
}

export default Layout

const LayoutContainer = styled.div`
  height: 100%;
`

const Main = styled.div`
  position: relative;
  height: calc(100% - 80px);
`
const StyledFooter = styled.footer`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 80px;
`
