import React from "react"

import styled, { keyframes } from "styled-components"
//import { Collapse, Panel, Button, Card } from "antd"
//import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons"

import { Color, Font } from "../config"

import { useColorsContext, useColorsDataLoading } from "../data/colorsContext"

const News = () => {
  const { ctxNews } = useColorsContext()
  useColorsDataLoading()
  function renderNews() {
    return (
      <NewsSlider>
        <NewsSliderContent>
          {ctxNews!.map(x => {
            return (
              <NewsSliderContentItem key={x.title}>
                <b>{x.date}</b> {x.title}
              </NewsSliderContentItem>
            )
          })}
        </NewsSliderContent>
      </NewsSlider>
    )
  }
  return (
    <NewsContainer>
      <NewsTitle>NEWS</NewsTitle>
      {ctxNews ? renderNews() : <NewsSlider> Loading...</NewsSlider>}
    </NewsContainer>
  )
}

export default News

const NewsContainer = styled.div`
  background-color: ${Color.BLACK};
  color: ${Color.WHITE};
  font-family: ${Font.NEWS};
  height: 40px;
  padding: 40px 0;
  a {
    color: ${Color.WHITE};
  }

  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  padding: 10px;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  height: 40px;
  overflow-wrap: break-word;
  text-align: center;
  text-size-adjust: 100%;
`

const NewsTitle = styled.div`
  text-align: left;
  float: left;
  font-weight: bold;
`

const NewsSlider = styled.div`
  overflow: hidden;
  float: right;
  width: 85%;

  @media (max-width: 767px) {
    width: 77% !important;
  }
`

const slideleft = keyframes`
  to {
    transform: translateX(-100%);
  }
`

const NewsSliderContent = styled.div`
  display: inline-block;
  white-space: nowrap;
  animation: ${slideleft} 25s infinite linear;
  :hover {
    animation-play-state: paused;
  }
`
const NewsSliderContentItem = styled.span`
  margin-right: 60px;
  :after {
    content: "";
    position: fixed;
    margin-left: 25px;
  }
`
