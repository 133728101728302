import { normalize } from "styled-normalize"
import { createGlobalStyle } from "styled-components"
import { Color } from "../config"

interface GlobalStylesProps {
  bgColor: string
}
export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
      ${normalize}

      html {
        box-sizing: border-box;
      }

      *,
      *::before,
      *::after {
        box-sizing: inherit;
      }

      body {
        background-color: ${(props) => props.bgColor} !important;
        font-family: "Helvetica" !important;
      }


      ::selection {
        color: white;
        background: ${() => Color.BLACK};
      }

      .grecaptcha-badge{
        visibility: collapse !important;
      }

      .menu-active{
        text-decoration: line-through;
      }

    `
